import React, { useState } from "react";
import {
  Container,
  Content,
  Panel,
  FlexboxGrid,
  Divider,
  Table,
  Form,
  Button,
  DateRangePicker,
} from "rsuite";
import SideNav from "components/SideNav";
import Header from "components/Header";
import {
  FcDocument,
  FcInTransit,
  FcFilingCabinet,
  FcMoneyTransfer,
} from "react-icons/fc";
import ModalUi from "components/Modal";
import { URL } from "config";
import moment from "moment";

const Body = ({
  data,
  sendMail,
  setEmailModalOpen,
  emailModalOpen,
  revenue,
  setDate,
}) => {
  const [email, setEmail] = useState("");
  const [selectedRow, setSelectedRow] = useState("");

  const assignChartData = (type, color) => {
    var array1 = [],
      array2 = [];
    if (data && data[type]) {
      data[type].map((data) => {
        array1.push(moment(data.date).format("DD"));
        array2.push(parseInt(data.total));
      });
    }

    // return { label: array1, data: array2 };
    return JSON.stringify({
      type: "bar",
      data: {
        labels: array1,
        datasets: [
          {
            label: type === "moving_graph" ? "Moving" : "Storage",
            data: array2,
            backgroundColor:
              type === "moving_graph"
                ? "rgba(255, 99, 132, 0.5)"
                : "rgba(96,197,197, 0.5)",
            borderColor:
              type === "moving_graph"
                ? "rgba(255, 99, 132, 1)"
                : "rgba(96,197,197, 1)",
            borderWidth: 1,
            barPercentage: 0.8,
            maxBarThickness: 15,
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            display: true,
            anchor: "end",
            align: "top",
            borderRadius: 6,
            backgroundColor: "rgb(238, 238, 238)",
            borderColor: "rgb(221,221,221)",
            font: {
              size: 8,
              color: "rgb(102,102,102)",
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "rgb(0,0,0)",
              },
              scaleLabel: {
                display: true,
                labelString: "Amount",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 10,
                fontColor: "rgb(0,0,0)",
              },
              scaleLabel: {
                display: true,
                labelString: "Day of Month",
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    });
  };

  const handleMovingEmailAction = (rowData) => {
    setEmail(rowData.email);
    setSelectedRow(rowData);
    setEmailModalOpen(!emailModalOpen);
  };

  const handleStorageEmailAction = (rowData) => {
    setEmail(rowData.email);
    setSelectedRow(rowData);
    setEmailModalOpen(!emailModalOpen);
  };

  return (
    <Container id="dashboard">
      <SideNav route="dashboard" />
      <Container>
        <Header title={"DASHBOARD"} />
        <Content className="dashboardContent">
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item colspan={6}>
              <Card
                title=" Moving Invoice"
                monthly={
                  data && data.monthly_moving_invoice
                    ? data.monthly_moving_invoice
                    : 0
                }
                daily={
                  data && data.daily_moving_invoice
                    ? data.daily_moving_invoice
                    : 0
                }
                icon={<FcInTransit />}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6}>
              <Card
                title="Storage Invoice"
                monthly={
                  data && data.monthly_storage_invoice
                    ? data.monthly_storage_invoice
                    : 0
                }
                daily={
                  data && data.daily_storage_invoice
                    ? data.daily_storage_invoice
                    : 0
                }
                icon={<FcFilingCabinet />}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6}>
              <Card
                title="Quotation"
                monthly={data && data.monthly_quote ? data.monthly_quote : "0"}
                daily={data && data.daily_quote ? data.daily_quote : "0"}
                icon={<FcDocument />}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Divider />

          <FlexboxGrid justify="space-around">
            <FlexboxGrid.Item colspan={9}>
              <DateCard
                title="Range"
                revenue={revenue.range}
                icon={<FcMoneyTransfer />}
                setDate={setDate}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8}>
              <RevenueCard
                title="Daily Revenue"
                revenue={revenue.today}
                icon={<FcMoneyTransfer />}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Divider />
          <br />
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item>
              <img
                src={`https://quickchart.io/chart?c=${assignChartData(
                  "moving_graph",
                  "red"
                )}`}
                width="900px"
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <br />
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item>
              <img
                src={`https://quickchart.io/chart?c=${assignChartData(
                  "storage_graph",
                  "blue"
                )}`}
                width="900px"
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <br />
          <br />

          <Divider />
          <h3>Last 10 Moving Invoice</h3>
          <Table
            height={420}
            data={data && data.last_10_moving ? data.last_10_moving : []}
          >
            <Table.Column width={120} align="center" fixed>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.Cell dataKey="moving_id" />
            </Table.Column>
            <Table.Column width={120} fixed="left">
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <span className="links">
                      <button onClick={() => handleMovingEmailAction(rowData)}>
                        {" "}
                        Email{" "}
                      </button>{" "}
                      |{" "}
                      <a
                        href={`${URL}dashboard/moving/${rowData.invoice_type}/${rowData.moving_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        PDF{" "}
                      </a>
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.Cell dataKey="created_at" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Pickup Location</Table.HeaderCell>
              <Table.Cell dataKey="pickup_location" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Drop Location</Table.HeaderCell>
              <Table.Cell dataKey="drop_location" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.Cell dataKey="email" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Phone</Table.HeaderCell>
              <Table.Cell dataKey="phone" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Moving Cost</Table.HeaderCell>
              <Table.Cell dataKey="moving_cost" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Total Cost</Table.HeaderCell>
              <Table.Cell dataKey="total" />
            </Table.Column>
          </Table>
          <br />
          <Divider />
          <br />
          <h3>Last 10 Storage Invoice</h3>
          <Table
            height={320}
            data={data && data.last_10_storage ? data.last_10_storage : []}
          >
            <Table.Column width={120} align="center" fixed>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.Cell dataKey="storage_id" />
            </Table.Column>
            <Table.Column width={120} fixed="left">
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <span className="links">
                      <button onClick={() => handleStorageEmailAction(rowData)}>
                        {" "}
                        Email{" "}
                      </button>{" "}
                      |{" "}
                      <a
                        href={`${URL}dashboard/storage/${rowData.invoice_type}/${rowData.storage_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        PDF{" "}
                      </a>
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.Cell dataKey="created_at" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.Cell dataKey="email" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Phone</Table.HeaderCell>
              <Table.Cell dataKey="phone" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Duration</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <span>
                      {rowData.store_duration} {rowData.duration_type}
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Size (sqft)</Table.HeaderCell>
              <Table.Cell dataKey={`size`} />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Storing Cost</Table.HeaderCell>
              <Table.Cell dataKey="storing_cost" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Total Cost</Table.HeaderCell>
              <Table.Cell dataKey="total" />
            </Table.Column>
          </Table>
        </Content>
      </Container>
      <ModalUi
        title="Add Email"
        size="sm"
        open={emailModalOpen}
        handleClose={() => setEmailModalOpen(!emailModalOpen)}
        Footer={() => (
          <>
            <Button
              appearance="primary"
              onClick={() => sendMail(selectedRow, email)}
            >
              Send Email
            </Button>
          </>
        )}
      >
        <>
          <Form>
            <Form.Group controlId="email">
              <Form.ControlLabel>Enter email</Form.ControlLabel>
              <Form.Control
                name="email"
                type="text"
                value={email}
                onChange={setEmail}
              />
            </Form.Group>
          </Form>
        </>
      </ModalUi>
    </Container>
  );
};

const Card = (props) => (
  <Panel {...props} shaded>
    <FlexboxGrid justify="space-around" align="middle">
      <FlexboxGrid.Item colspan={4}>
        <div className="dashboardCardIcon">{props.icon}</div>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={6} className="dashboardCardValue">
        {props.title}
      </FlexboxGrid.Item>
    </FlexboxGrid>
    <Divider />
    <FlexboxGrid justify="space-around" align="middle">
      <FlexboxGrid.Item>
        <div className="dashboardCardTitle">Monthly: {props.monthly}</div>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item className="dashboardCardTitle">
        Daily: {props.daily}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </Panel>
);

const RevenueCard = (props) => (
  <Panel {...props} shaded>
    <FlexboxGrid justify="space-around" align="middle">
      <FlexboxGrid.Item colspan={4} className="dashboardCardIcon">
        {props.icon}
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={8} className="dashboardCardValue">
        {props.title}
      </FlexboxGrid.Item>
    </FlexboxGrid>
    <Divider className="revenueCardDivider" />
    <FlexboxGrid justify="space-around" align="middle">
      <FlexboxGrid.Item colspan={4}>
        <div className="dashboardCardValue">Today</div>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4}>
        <div className="dashboardCardValue">{props.revenue}</div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </Panel>
);

const DateCard = (props) => (
  <Panel {...props} shaded>
    <FlexboxGrid justify="space-around" align="middle">
      <FlexboxGrid.Item colspan={2} className="dashboardCardIcon">
        {props.icon}
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={12} className="dashboardRevenueCardIcon">
        <DateRangePicker
          size="lg"
          defaultValue={[
            moment().startOf("month").toDate(),
            moment().startOf("month").toDate(),
          ]}
          block
          onChange={(e) => props.setDate(e)}
          range={[]}
          hoverRange={(date) => [
            new Date(date.setDate(date.getDate())),
            new Date(date.setDate(date.getDate())),
          ]}
          showOneCalendar
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
    <Divider className="revenueCardDivider" />
    <FlexboxGrid justify="space-around" align="middle">
      <FlexboxGrid.Item colspan={3} className="dashboardCardValue">
        {props.title}
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4}>
        <div className="dashboardCardValue">{props.revenue}</div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </Panel>
);

export default Body;
