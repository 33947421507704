import { useEffect, useState } from "react";
import Body from "./Body";
import { Dashboard } from "Api";
import { useDispatch } from "react-redux";
import { sendEmail, getDashboardRevenue } from "Api";
import { loadingStart, loadingStop } from "reduxData/action";
import moment from "moment";
import "./style.css";

const Main = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [date, setDate] = useState([
    moment().startOf("month").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ]);
  const [revenue, setRevenue] = useState({ range: 0, today: 0 });

  useEffect(() => {
    dispatch(loadingStart());
    Dashboard()
      .then((result) => {
        if (result.data.status) {
          setData(result.data.data);
        } else {
          setData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(async () => {
    if (date) {
      dispatch(loadingStart());
      await getDashboardRevenue(
        moment(date[0]).format("YYYY-MM-DD"),
        moment(date[1]).format("YYYY-MM-DD")
      ).then(({ data }) =>
        data.status ? setRevenue(data.data) : alert(data.message)
      );
      dispatch(loadingStop());
    }
  }, [date]);

  const sendMail = async (selectedRow, email) => {
    dispatch(loadingStart());
    selectedRow.moving_id
      ? await sendEmail("moving", selectedRow.moving_id, {
          emails: email,
        }).then((data) => checkEmailSuccess(data.data))
      : await sendEmail("storage", selectedRow.storage_id, {
          emails: email,
        }).then((data) => checkEmailSuccess(data.data));
    dispatch(loadingStop());
  };

  const checkEmailSuccess = (data) => {
    if (data.status) {
      alert("Email sent Successfully");
    } else alert("OOPS! Something Went Wrong!");
    setEmailModalOpen(false);
  };

  return (
    <Body
      data={data}
      sendMail={sendMail}
      emailModalOpen={emailModalOpen}
      setEmailModalOpen={setEmailModalOpen}
      setDate={setDate}
      revenue={revenue}
    />
  );
};

export default Main;
