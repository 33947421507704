import { Modal } from "rsuite";

const ModalUi = ({ title, size, open, handleClose, Footer, Body, ...props }) => (
  <Modal size={size} open={open} onClose={handleClose}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {/*<Body />*/}
      {
        props.children
      }
    </Modal.Body>
    <Modal.Footer>
      <Footer />
    </Modal.Footer>
  </Modal>
);

export default ModalUi;
