import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { loadingStart, loadingStop, loggedin } from "reduxData/action";
import Body from "./Body";
import { Login } from "Api";
import "./style.css";

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogin = () => {
    setError("");
    dispatch(loadingStart());
    Login(formValue)
      .then(async (result) => {
        if (result.data.status) {
          localStorage.setItem(
            "current_user",
            JSON.stringify(result.data.data)
          );
          dispatch(loggedin(result.data.data));
          navigate("/dashboard");
        } else {
          setError(result.data.message);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };
  const [formValue, setFormValue] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  return (
    <Body
      formValue={formValue}
      setFormValue={setFormValue}
      handleLogin={handleLogin}
      error={error}
    />
  );
};

export default Main;
