import React, { useState } from "react";
import {
  Container,
  Content,
  Panel,
  FlexboxGrid,
  Pagination,
  Button,
  Form,
} from "rsuite";
import SideNav from "components/SideNav";
import Header from "components/Header";
import { Table } from "rsuite";
import ModalUi from "components/Modal";
import { URL } from "config";
import { sendEmail } from "Api";
import { useDispatch } from "react-redux";
import { loadingStart, loadingStop } from "reduxData/action";

const Body = ({ data, page, setPage, total }) => {
  const dispatch = useDispatch();

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const handleEmailAction = (rowData) => {
    setEmail(rowData.email);
    setSelectedRow(rowData);
    setEmailModalOpen(!emailModalOpen);
  };

  const sendMail = async () => {
    dispatch(loadingStart());
    await sendEmail("moving", selectedRow.moving_id, {
      emails: email,
    }).then((data) => checkEmailSuccess(data.data));

    dispatch(loadingStop());
  };

  const checkEmailSuccess = (data) => {
    if (data.status) {
      alert("Email sent Successfully");
    } else alert("OOPS! Something Went Wrong!");
    setEmailModalOpen(false);
  };

  return (
    <Container id="moving">
      <SideNav route="moving-invoice" />
      <Container>
        <Header title={"MOVING"} />
        <Content>
          <FlexboxGrid
            justify="space-between"
            align="middle"
            className="movingTableTop"
          >
            <FlexboxGrid.Item>
              <Panel>
                <h3>Invoice Details</h3>
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Table
            height={document.documentElement.clientHeight * 0.6}
            className="tableView"
            data={data}
          >
            <Table.Column width={50} align="center" fixed>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.Cell dataKey="moving_id" />
            </Table.Column>
            <Table.Column width={120} fixed>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <span className="links">
                      <button onClick={() => handleEmailAction(rowData)}>
                        {" "}
                        Email{" "}
                      </button>{" "}
                      |{" "}
                      <a
                        href={`${URL}dashboard/moving/${rowData.invoice_type}/${rowData.moving_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        PDF{" "}
                      </a>
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.Cell dataKey="created_at" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Pickup Location</Table.HeaderCell>
              <Table.Cell dataKey="pickup_location" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Drop Location</Table.HeaderCell>
              <Table.Cell dataKey="drop_location" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.Cell dataKey="email" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Phone</Table.HeaderCell>
              <Table.Cell dataKey="phone" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Moving Cost</Table.HeaderCell>
              <Table.Cell dataKey="moving_cost" />
            </Table.Column>
            <Table.Column width={200} align="center">
              <Table.HeaderCell>Total Cost</Table.HeaderCell>
              <Table.Cell dataKey="total" />
            </Table.Column>
          </Table>
          <br />
          <br />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["pager"]}
            total={parseInt(total)}
            limit={20}
            limitOptions={[20]}
            activePage={page}
            onChangePage={(data) => {
              setPage(data);
            }}
            //onChangeLimit={()=>null}
          />
        </Content>
      </Container>
      <ModalUi
        title="Add Email"
        size="sm"
        open={emailModalOpen}
        handleClose={() => setEmailModalOpen(!emailModalOpen)}
        Footer={() => (
          <>
            <Button appearance="primary" onClick={sendMail}>
              Send Email
            </Button>
          </>
        )}
      >
        <>
            <Form>
              <Form.Group controlId="email">
                <Form.ControlLabel>Enter email</Form.ControlLabel>
                <Form.Control
                  name="email"
                  type="text"
                  value={email}
                  onChange={setEmail}
                />
              </Form.Group>
            </Form>
          </>
      </ModalUi>
    </Container>
  );
};

export default Body;
