import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import * as Pages from "../pages";
import { loggedin } from "reduxData/action";

const Navigation = () => {
  const isLoaderActive = useSelector((state) => state.sessionReducer.isLoading);
  const user = useSelector((state) => state.sessionReducer.userSession);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loggedin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoaderActive && (
        <div className="loader">
          <Loader size="lg" content="Loading ..." vertical />
        </div>
      )}

      <Routes>
        <Route
          path="/"
          element={
            !user ? <Pages.Home /> : <Navigate replace to="/dashboard" />
          }
        />
        {user && (
          <Route path="dashboard">
            <Route index element={<Pages.Dashboard />} />
            <Route path="moving-invoice" element={<Pages.Moving />} />
            <Route path="storage-invoice" element={<Pages.Storage />} />
            <Route
              path="moving-quotation"
              element={<Pages.MovingQuotation />}
            />
            <Route
              path="storage-quotation"
              element={<Pages.StorageQuotation />}
            />
          </Route>
        )}
        <Route path="*" element={<Pages.NotFound />} />
      </Routes>
    </div>
  );
};

export default Navigation;
