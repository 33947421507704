import axios from "axios";
import { URL } from "config/index.js";

export function Login(data) {
  return axios.post(`${URL}auth`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function changePassword(data) {
  return axios.post(`${URL}auth/changePassword`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function Dashboard() {
  return axios.get(`${URL}dashboard/allCountData`);
}

export function MovingInvoice(page) {
  return axios.get(`${URL}moving/invoiceList?page=${page}`);
}

export function StorageInvoice(page) {
  return axios.get(`${URL}storage/invoiceList?page=${page}`);
}

export function movingQuote(page) {
  return axios.get(`${URL}moving/quoteList?page=${page}`);
}

export function storageQuote(page) {
  return axios.get(`${URL}storage/quoteList?page=${page}`);
}

export function sendEmail(type, id, data) {
  return axios.post(`${URL}dashboard/email/${type}/${id}`, data);
}

export function getDashboardRevenue(sdate, edate) {
  return axios.get(
    `${URL}dashboard/revenue/?start_date=${sdate}&end_date=${edate}`
  );
}
