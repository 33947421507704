import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Navbar, Header, Nav, Dropdown, Button, Form } from "rsuite";
import { FaUserAlt } from "react-icons/fa";
import "./style.css";
import ModalUi from "components/Modal";
import { changePassword } from "Api";
import { useDispatch, useSelector } from "react-redux";
import { loadingStart, loadingStop, logout } from "reduxData/action";

const Component = ({ title }) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [changePasswordModalOpen, setchangePasswordModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const user = useSelector((state) => state.sessionReducer.userSession);

  const handlePasswordChange = () => {
    dispatch(loadingStart());
    changePassword({
      username: user.username,
      password: newPassword,
    }).then((data) => {
      dispatch(loadingStop());
      if (data.status) {
        setchangePasswordModalOpen(false);
        alert("Password Changed Successfully");
      }
    });
  };

  const handleLogout = async () => {
    dispatch(loadingStart());
    localStorage.removeItem("current_user");
    dispatch(logout());
    Navigate("/");
    dispatch(loadingStop());
  };

  return (
    <>
      <Header id="navbar">
        <Navbar appearance="subtle">
          <Nav>
            <Nav.Item>
              <h4>{title}</h4>
            </Nav.Item>
          </Nav>

          <Nav pullRight className="profile">
            <Dropdown
              icon={<FaUserAlt className="nav-icons" />}
              title="Profile"
            >
              <Dropdown.Item
                eventKey="4"
                onClick={() =>
                  setchangePasswordModalOpen(!changePasswordModalOpen)
                }
              >
                Change Password
              </Dropdown.Item>
              <Dropdown.Item eventKey="5" onClick={handleLogout}>
                Logout
              </Dropdown.Item>
            </Dropdown>
          </Nav>
        </Navbar>
      </Header>
      <ModalUi
        title="Change Password"
        size="md"
        open={changePasswordModalOpen}
        handleClose={() => setchangePasswordModalOpen(!changePasswordModalOpen)}
        Footer={() => (
          <>
            <Button appearance="primary" onClick={handlePasswordChange}>
              Change
            </Button>
          </>
        )}
        Body={() => (
          <>
            <Form>
              <Form.Group controlId="password">
                <Form.ControlLabel>Enter Password</Form.ControlLabel>
                <Form.Control
                  name="password"
                  type="password"
                  autoComplete="off"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e)}
                />
              </Form.Group>
            </Form>
          </>
        )}
      />
    </>
  );
};

export default Component;
