import { useEffect, useState } from "react";
import Body from "./Body";
import "./style.css";
import { storageQuote } from "Api";
import { useDispatch } from "react-redux";
import { loadingStart, loadingStop } from "reduxData/action";

const Main = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    dispatch(loadingStart());
    storageQuote(page)
      .then((result) => {
        if (result.data.status) {
          setData(result.data.data.list);
          setTotal(result.data.data.total);
        } else {
          setData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };
  return (
    <Body
      data={data}
      total={total}
      onPageChange={fetchData}
      page={page}
      setPage={setPage}
    />
  );
};

export default Main;
