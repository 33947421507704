import React from "react";
import { Sidebar, Sidenav, Nav, Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import {
  FcBearish,
  FcAutomotive,
  FcDepartment,
  FcViewDetails,
} from "react-icons/fc";
import "./style.css";

const Component = ({ route }) => {
  const navigate = useNavigate();
  return (
    <Sidebar id="side-nav" collapsible>
      <div className="side-bar-container">
        <h3 className="text-center brand">UAE Storages</h3>
        <Divider />
        <Sidenav expanded={true} defaultOpenKeys={["1"]} appearance="subtle">
          <Sidenav.Body>
            <Nav>
              <Nav.Item
                eventKey="1"
                active={route === "dashboard"}
                icon={<FcBearish className="nav-icons" />}
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </Nav.Item>
              <Nav.Item
                eventKey="2"
                active={route === "moving-invoice"}
                icon={<FcAutomotive className="nav-icons" />}
                onClick={() => navigate("/dashboard/moving-invoice")}
              >
                Moving Invoice
              </Nav.Item>
              <Nav.Item
                eventKey="3"
                active={route === "storage-invoice"}
                icon={<FcDepartment className="nav-icons" />}
                onClick={() => navigate("/dashboard/storage-invoice")}
              >
                Storage Invoice
              </Nav.Item>
              <Nav.Item
                eventKey="4"
                active={route === "moving-quotation"}
                icon={<FcViewDetails className="nav-icons" />}
                onClick={() => navigate("/dashboard/moving-quotation")}
              >
                Moving Quote
              </Nav.Item>
              <Nav.Item
                eventKey="5"
                icon={<FcViewDetails className="nav-icons" />}
                active={route === "storage-quotation"}
                onClick={() => navigate("/dashboard/storage-quotation")}
              >
                Storage Quote
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    </Sidebar>
  );
};

export default Component;
