import React from "react";
import { Container, Content, Form, ButtonToolbar, Button } from "rsuite";
import { Panel } from "rsuite";
import { FlexboxGrid } from "rsuite";
import Logo from "assets/images/logo.jpg";

const Body = ({ formValue, setFormValue, handleLogin, error }) => {
  return (
    <Container id="home">
      <Content>
        <FlexboxGrid
          className="login-panel-flexbox"
          justify="center"
          align="middle"
        >
          <FlexboxGrid.Item colspan={8}>
            <Panel className="login-panel" header="" shaded>
              <div className="login-overlay-header">
                <img src={Logo} className="logo" alt="" />
              </div>
              <Form fluid onChange={setFormValue} formValue={formValue}>
                <Form.Group controlId="username">
                  <Form.ControlLabel>Username</Form.ControlLabel>
                  <Form.Control name="username" type="username" />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.ControlLabel>Password</Form.ControlLabel>
                  <Form.Control name="password" type="password" autoComplete="off" />
                </Form.Group>
                <p className="error">{error}</p>
                <Form.Group>
                  <ButtonToolbar>
                    <Button appearance="primary" block onClick={handleLogin}>
                      Login
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  );
};

export default Body;
